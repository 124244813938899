@import '../public/assets/icomoon/style.css';
@import '@ag-grid-community/styles/ag-grid.css';
@import 'react-grid-layout/css/styles.css';
@import 'react-resizable/css/styles.css';

html,
body,
#__next {
  height: 100%;
  width: 100%;
}
* {
  box-sizing: border-box;
}

body.resizing {
  overflow: hidden;
  user-select: none;
}

body.resizing > *:not(.sidebar-resize-handle) {
  pointer-events: none;
}

/* FOR PRINTING */
@media print {
  * {
    transition: none !important;
    animation: none !important;
  }
  html {
    overflow: visible;
    height: initial;
  }

  #cigro-sidebar {
    display: none;
  }

  #cigro-side-navbar {
    display: none;
  }

  #cigro-report-right-drawer {
    display: none;
  }

  /* THE MAIN BODY */
  #cigro-main-element {
    height: fit-content;
    overflow: visible;
  }

  #cigro-dashboard-left-drawer {
    margin-right: 0;
  }

  #page-dashboard-id {
    margin: 0;
    max-width: 100%;
    padding: 0;
  }

  #cigro-dashboard-left-drawer .CgDashboardLeftDrawer {
    width: 100%;
  }

  /* DASHBOARD HEADING SECTION */
  #CgDashboardMenuGroup {
    display: none;
  }

  #CgMoreActionsMenu {
    display: none;
  }

  .CgDashboardFilterSection > *:not(.CgDashboardFilters) {
    display: none;
  }

  #CgDashboardFilters {
    height: unset;
    overflow: visible;
  }

  #CgFilterActions {
    display: none;
  }

  #CgWidgetCreateButton {
    display: none;
  }

  /* THE WIDGET ITEM */
  #CgWidgetRow {
    page-break-inside: avoid;
  }

  #CgWidgetRefreshButton,
  #CgWidgetViewTabs {
    display: none;
  }

  .cg-dont-print-this {
    display: none;
  }
}

/* AG GRID */

.ag-dnd-ghost {
  height: 32px;
  padding: 8px 16px 8px 8px;
  border-radius: 8px;
  background-color: #f8f8f9; /* neutralV2.7 */
  border: 1px solid #e9edf1; /* neutralV2.5 */
  min-width: 160px;
  gap: 4px;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1); /*shadow.3*/
}

.ag-dnd-ghost .ag-dnd-ghost-label {
  color: #676e7b; /*text.1*/
  font-size: 12px;
  font-family: 'Pretendard Variable';
}

.ag-dnd-ghost .ag-dnd-ghost-icon {
  /* Icomoon */
  font-family: 'icomoon' !important; /* use !important to prevent issues with browser extensions that change fonts */
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* --ag-icon-font-code-arrows: '\e91e';
  --ag-icon-font-code-left: '\e91e'; */
  color: #676e7b; /*text.1*/
}

.ag-dnd-ghost .ag-dnd-ghost-icon .ag-icon::before {
  content: '\e91e' !important;
}

/* FOR REACT-GRID-LAYOUT */
.react-grid-layout {
  transition: none; /* More lightweight transition */
}
